/**
 * initializer for new AddressPreview - main.js is just a thin wrapper
 * so we can have dev and prod entry...
 */

import AddressPreview from '@v/components/AddressPreview.vue'
import Vue from 'vue'

var $ = jQuery // eslint-disable-line no-undef

export default {
  /**
   * map of our Vue instances
   */
  instances: {},

  /**
   * show an AuditLogViewer for the model record with id in the supplied DOM element.
   */
  create (domId, store) {
    if (!this.instances[domId]) {
      var $div = $('#' + domId)

      this.instances[domId] = new Vue({
        store,
        render: createElement => createElement(AddressPreview),
        created: function () {
          // this.$store.dispatch('register', { model, id })
        }
      }).$mount($div[0])
    }

    return this.instances[domId]
  }
}
