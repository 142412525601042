import Vue from 'vue'
import Vuex from 'vuex'
import debounce from 'lodash/debounce'

Vue.use(Vuex)

function vueSetByPath (obj, path, value) {
  if (typeof path === 'string') {
    return vueSetByPath(obj, path.split('.'), value)
  } else if (path.length === 1 && value !== undefined) {
    // obj[path[0]] = value
    Vue.set(obj, path[0], value)
    return obj
  } else if (path.length === 0) {
    return obj
  } else {
    if (!obj[path[0]]) {
      // set empty object at current key
      Vue.set(obj, path[0], {})
    }

    return vueSetByPath(obj[path[0]], path.slice(1), value)
  }
}

function val ($field) {
  if ($field.is('select') && $field.val()) {
    return $field.find('option:selected').text()
  } else {
    return $field.val()
  }
}

export default new Vuex.Store({
  state: {
    fieldsRegistered: false,

    fields: {
    }
  },

  mutations: {

    /**
     * sets value into fields at path (dot notation)
     */
    setField (state, { path, value }) {
      vueSetByPath(state.fields, path, value)
    },

    fieldsRegistered (state) {
      state.fieldsRegistered = true
    }

  },

  actions: {
    /**
     * initially register fields by installing a debounced handler on keyUp
     * that commits changes via setField
     *
     * expects fields to be jQuery object
     */
    registerFields (store, { fields, jQuery }) {
      if (store.state.fieldsRegistered) {
        throw new Error('can only register fields once!')
      }

      fields.each(function () {
        let $field = jQuery(this)

        // path will not change
        let path = $field.attr('name').slice(5, -1).replace(/\]\[/g, '.')

        // set initial value
        let value = val($field)
        store.commit('setField', { path, value })

        let onUpdateDebounced = debounce(() => {
          let value = val($field)
          store.commit('setField', { path, value })
        }, 250)

        if ($field.is(':text') || $field.is('textarea')) {
          $field.on('keyup change', onUpdateDebounced)
        } else if ($field.is('select')) {
          $field.on('change', onUpdateDebounced)
        }
      })

      store.commit('fieldsRegistered')
    }
  }
})
