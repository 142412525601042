var Handlebars = require("../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.letterhead_line2 : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.letterhead_line1 : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.letterhead_line2 : stack1), depth0))
    + "<br>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.letterhead_line1 : stack1), depth0))
    + "<br>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.letterhead_line2 : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.letterhead_line2 : stack1), depth0))
    + "<br>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.organisation : stack1), depth0))
    + "<br>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.department : stack1), depth0))
    + "<br>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.PrimaryAddress : depth0)) != null ? stack1.country : stack1), depth0))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.letterhead_form_of_address : stack1), depth0))
    + "<br>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.letterhead_line1 : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.job_title2 : stack1), depth0))
    + " "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.title : stack1), depth0)) != null ? stack1 : "")
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.first_name : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.last_name : stack1), depth0))
    + " "
    + ((stack1 = alias1(((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.title_anglo : stack1), depth0)) != null ? stack1 : "")
    + "<br>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.organisation : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.Contact : depth0)) != null ? stack1.department : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PrimaryAddress : depth0)) != null ? stack1.street : stack1), depth0))
    + "<br>\n"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PrimaryAddress : depth0)) != null ? stack1.postal_code : stack1), depth0))
    + " "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.PrimaryAddress : depth0)) != null ? stack1.city : stack1), depth0))
    + "<br>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = (depth0 != null ? depth0.PrimaryAddress : depth0)) != null ? stack1.country : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});