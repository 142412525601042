<template>
  <div id="addressPreviewNew" class="address-preview" v-html="addressPreview">
  </div>
</template>

<script>
import extend from 'lodash/extend'

export default {
  name: 'AddressPreview',

  data: function () {
    return {
      template: require('./templates/addressPreview.handlebars')
    }
  },

  computed: {
    /**
     * does some cleanup of fields, currently:
     * - remove p tags in Contact.title and Contact.title_anglo
     * - unset PrimaryAddress.country if ÖSTERREICH
     * - PrimaryAddress.country toUpperCase
     */
    fieldsCleaned: function () {
      let fields = extend({}, this.$store.state.fields)

      let keys = [ 'title', 'title_anglo' ]
      for (var key of keys) {
        if (fields.Contact[key]) {
          fields.Contact[key] = fields.Contact[key].replace(/<\/?p>/g, '')
        }
      }

      if (fields.PrimaryAddress.country === 'Österreich') {
        fields.PrimaryAddress.country = ''
      } else {
        fields.PrimaryAddress.country = fields.PrimaryAddress.country.toUpperCase()
      }

      return fields
    },

    addressPreview: function () {
      let fields = this.fieldsCleaned
      return this.template(fields)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
</style>
