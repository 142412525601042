import TemplatedSuggestions from './init_templated_suggestions'
import AddressPreviews from './init_address_preview'
import store from './store'

// EFW styles
require('@less/efw/main.less')

var $ = jQuery // eslint-disable-line no-undef

$.my.TemplatedSuggestions = TemplatedSuggestions
$.my.AddressPreviews = AddressPreviews

$(function () {
  if (!store.state.fieldsRegistered) {
    let fields = $('[name^="data"]')
    store.dispatch('registerFields', { fields, jQuery: $ })
  }

  $('input.templated-suggestions').each(function () {
    $.my.TemplatedSuggestions.create(this.id)
  })

  var addressPreviewId = 'addressPreview'
  var $addressPreview = $('#' + addressPreviewId)
  if ($addressPreview.length) {
    $.my.AddressPreviews.create(addressPreviewId, store)
  }

  $.my.init.dateTimePickers()

  // XXX switch all this to $.my.init system

  installAjaxLinks()

  highlightFulltextTerms()

  $('input[type=checkbox][name$="[birthdate_unknown]"]').birthdateUnknown()
})
