/**
 * actual initializer for auditLogViewer app - main.js is just a thin wrapper
 * so we can have dev and prod entry...
 *
 * ---
 *
 * We create a global function to initialise the viewer into a DOM element,
 * taking Model and ID as arguments. Store is single instance, shared by
 * different Vue instances...
 *
 * This way it is possible to have several viewers at once, while the store can
 * effectively cache the data...
 *
 */

import Vue from 'vue'
import TemplatedSuggestions from '@v/components/TemplatedSuggestions.vue'

var $ = jQuery // eslint-disable-line no-undef

export default {
  /**
   * map of our Vue instances
   */
  instances: {},

  fieldsRegistered: false,

  /**
   * show an AuditLogViewer for the model record with id in the supplied DOM element.
   */
  create (domId, store) {
    if (!this.instances[domId]) {
      var $input = $('#' + domId)
      var $vueWrapper = $('<div></div>')
      $vueWrapper.insertAfter($input)
      $input.hide()

      this.instances[domId] = new Vue({
        // router,
        store,
        render: createElement => createElement(TemplatedSuggestions, {
          props: {
            onUpdate: function (text) {
              $input.val(text)
            }
          }
        }),
        created: function () {
          // this.$store.dispatch('register', { model, id })
        }
      }).$mount($vueWrapper[0])
    }

    return this.instances[domId]
  }
}
