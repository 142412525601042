<template>
  <div class="templated-suggestions">
    <vue-autosuggest
       :suggestions="renderedTemplates"
       :input-props="{id:'autosuggest__input', onInputChange: onInputChange, placeholder:'Do you feel lucky, punk?'}"
       @selected="onSelected"
       @click="clickHandler"
       >
       <template slot-scope="{suggestion}">
         <span class="suggestion-item">{{suggestion.item}}</span>
       </template>
     </vue-autosuggest>
  </div>
</template>

<script>
/**
 * TODO PLAN:
 *
 * props that need to be supplied:
 * - list of (compiled) Handlebar templates
 * - Handlebars runtime
 * - store with data from other form inputs
 *   - shall have plugin, that listens to all form inputs. this plugin is glue
 *     between vue components and legacy inputs. Could also write content from
 *     components back to their (hidden) inputs!!??
 */
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'TemplatedSuggestions',
  data: function () {
    return {
      fields: {
        Contact: {
          first_name: 'Testerin',
          last_name: 'Testwoman'
        }
      },

      templates: [
        require('./templates/standard.handlebars'),
        require('./templates/zweites.handlebars'),
        'drittes Template',
        '4. Template'
      ]
    }
  },

  props: {
    onUpdate: {
      type: Function
    }
  },
  components: {
    VueAutosuggest
  },

  computed: {
    renderedTemplates: function () {
      let fields = this.fields
      let data = this.templates.map((item) => {
        if (typeof (item) === 'function') {
          return item(fields)
        }
        return item
      })
      return [ { data } ]
    }
  },

  methods: {
    testChange: function () {
      this.fields = {
        Contact: {
          first_name: 'Tester',
          last_name: 'Testman'
        }
      }
    },

    onInputChange: function (text, oldText) {
      console.log('onInputChange', text)
      if (this.$props.onUpdate) {
        this.$props.onUpdate(text)
      }
    },

    onSelected: function (item) {
      console.log('selectHandler', item.label)
      if (this.$props.onUpdate) {
        this.$props.onUpdate(item.label)
      }
    },

    clickHandler: function () {
      console.log('clickHandler')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.autosuggest__results {
  position: absolute;
  z-index: 1000;
  background-color: white;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  ul {
    padding: 5px 0;
    margin: 0;
    list-style: none;

    li {
      .suggestion-item {
        display: block;
        padding: 3px 20px;

        &:hover {
          background-color: #a3bcc2;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
